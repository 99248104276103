import React from 'react'
import { connect } from 'react-redux'
import { List, Badge, Skeleton, Button, Input } from 'antd'
// import { MessageFilled } from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'
import { injectIntl } from 'react-intl'
import actions from 'redux/virtual/chat/actions'
import DescriptionFormatter from '../../LayoutComponent/DescriptionFormatter'
import TitleFormatter from '../../LayoutComponent/TitleFormatter'
import ChatAvatar from '../../LayoutComponent/ChatAvatar'

// import styles from '../../style.module.scss'
/* eslint-disable prefer-template,no-unused-expressions */
const mapStateToProps = ({ Chat, virtualUser }) => ({ Chat, virtualUser })

const { Search } = Input

@connect(mapStateToProps)
class AttendeeMessageWindow extends React.Component {
  constructor(props) {
    super(props)
    const {
      dispatch,
      virtualUser: { roles },
    } = props
    if (roles.includes('ROLE_EXHIBITOR')) {
      dispatch({
        type: actions.SET_STATE,
        payload: {
          loadingAttendeeList: false,
        },
      })
    }
  }

  checkCommunication = id => {
    const {
      Chat: { channelData },
    } = this.props
    if (Object.prototype.hasOwnProperty.call(channelData, id)) {
      return channelData[id].channelID
    }
    return null
  }

  openChat = id => {
    const { dispatch } = this.props
    const result = this.checkCommunication(id)
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentChat: id,
        currentScreen: 'chat',
        userType: 'attendee',
      },
    })

    if (result != null) {
      dispatch({
        type: actions.SET_INSIDE_CHANNEL,
        payload: {
          chatWith: id,
          key: 'readStatus',
          value: 'read',
        },
      })
      dispatch({
        type: actions.SET_INSIDE_CHANNEL,
        payload: {
          chatWith: id,
          key: 'unreadCount',
          value: 0,
        },
      })
    }
  }

  // convertTime = (date) => {

  // }

  formatDescription = (messages, sentenceLength) => {
    if (messages.length > 0) {
      const messageNode = messages[messages.length - 1]
      if (messageNode.category === 'videomeet') {
        return 'Join Meeting'
      }
      if (messageNode.category === 'visitingcard') {
        return 'Visiting Card'
      }
      if (messageNode.category === 'message') {
        const actualText = messageNode.text
        if (actualText.length > sentenceLength) {
          return `${actualText.substring(0, sentenceLength)}...`
        }
        return actualText
      }
    }
    return ''
  }

  assignExhibitor = event => {
    const { dispatch } = this.props
    const chatWith = event.target.getAttribute('data-chatid')

    dispatch({
      type: actions.ASSIGN_EXHIBITOR,
      payload: {
        chatWith,
      },
    })

    dispatch({
      type: actions.DELETE_REQUEST,
      payload: {
        chatWith,
      },
    })
  }

  onLoadMore = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.LOAD_MORE_USERS,
    })
  }

  searchUser = searchkey => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SEARCH_USER,
      payload: {
        searchkey,
      },
    })
  }

  render() {
    const {
      Chat: {
        allUsers,
        loadingAttendeeSearch,
        initloadingAttendeeList,
        loadingAttendeeList,
        channelData,
        currentTab,
        requestData,
      },
      intl,
    } = this.props
    const usersSerialized = []
    if (currentTab === 'attendees') {
      Object.entries(allUsers).map(key => {
        usersSerialized.push(key[1])
        return ''
      })
    } else if (currentTab === 'requests') {
      Object.entries(requestData).map(key => {
        const finalJson = {
          id: key[0],
          name: key[1].userMeta.name,
          avatar_type: key[1].userMeta.avatar_type,
          avatar_data: key[1].userMeta.avatar_data,
          avatar_color: key[1].userMeta.avatar_color,
          login_status: key[1].userMeta.login_status,
          designation: key[1].userMeta.designation,
          organization: key[1].userMeta.organization,
          description: '',
        }
        usersSerialized.push(finalJson)
        return ''
      })
    } else {
      Object.entries(channelData).map(key => {
        const finalJson = {
          id: key[0],
          name: key[1].userMeta.name,
          avatar_type: key[1].userMeta.avatar_type,
          avatar_data: key[1].userMeta.avatar_data,
          avatar_color: key[1].userMeta.avatar_color,
          login_status: key[1].userMeta.login_status,
          designation: '',
          organization: '',
          description: this.formatDescription(key[1].messages, 25),
          lastTime: key[1].lastTime,
          unreadCount: key[1].unreadCount,
        }
        usersSerialized.push(finalJson)
        return ''
      })
      usersSerialized.sort(function(x, y) {
        return y.lastTime - x.lastTime
      })
    }

    const loadMore =
      currentTab === 'attendees' && !initloadingAttendeeList ? (
        <div
          style={{
            textAlign: 'center',
            marginTop: 12,
            height: 32,
            lineHeight: '32px',
          }}
        >
          <Button onClick={this.onLoadMore}>Load More</Button>
        </div>
      ) : (
        ''
      )
    return (
      <>
        <br />
        {currentTab === 'attendees' ? (
          <Search
            placeholder={intl.formatMessage({ id: 'search.placeholder' })}
            loading={loadingAttendeeSearch}
            enterButton
            onSearch={this.searchUser}
          />
        ) : (
          ''
        )}
        <br />
        <br />
        <Scrollbars style={{ height: 'calc(100vh - 17rem)' }}>
          <List
            itemLayout="horizontal"
            dataSource={usersSerialized}
            loading={currentTab === 'attendees' ? initloadingAttendeeList : false}
            loadMore={loadMore}
            renderItem={item => (
              <List.Item
                // actions={[
                //   <Tooltip title="Chat">
                //     <Button
                //       className={styles.listItemAction}
                //       type="primary"
                //       shape="circle"
                //       icon={<MessageFilled />}
                //     />
                //   </Tooltip>,
                // ]}
                extra={
                  currentTab === 'requests' ? (
                    <Button type="primary" data-chatid={item.id} onClick={this.assignExhibitor}>
                      Accept
                    </Button>
                  ) : (
                    <Badge count={item.unreadCount} style={{ backgroundColor: '#52c41a' }} />
                  )
                }
              >
                <Skeleton
                  loading={currentTab === 'messages' ? false : loadingAttendeeList}
                  active
                  avatar
                >
                  <List.Item.Meta
                    style={{ cursor: 'pointer' }}
                    avatar={
                      <ChatAvatar
                        avatarType={item.avatar_type}
                        avatarData={item.avatar_data}
                        avatarColor={item.avatar_color}
                        shape="square"
                        size={55}
                        loginStatus={item.login_status}
                      />
                    }
                    title={<TitleFormatter name={item.name} style={{ marginBottom: '0px' }} />}
                    description={
                      currentTab === 'attendees' || currentTab === 'requests' ? (
                        <DescriptionFormatter
                          designation={item.designation}
                          organization={item.organization}
                        />
                      ) : (
                        <p>{item.description}</p>
                      )
                    }
                    onClick={() => {
                      currentTab !== 'requests' ? this.openChat(item.id) : ''
                    }}
                  />
                </Skeleton>
              </List.Item>
            )}
          />
        </Scrollbars>
      </>
    )
  }
}

export default injectIntl(AttendeeMessageWindow)
