import actions from './actions'

const initialState = {
  scenes: {},
  defaultScene: '',
  currentScene: '',
  agenda: {},
  currentAgenda: '',
  exhibitors: {},
  moderators: {},
  currentNotificationScreen: 'category',
  notificationCategory: '',
  pubnub: null,
  menu: [],
  speakers: {},
  roles: {},
  notifications: []
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_SCENE:
      return {
        ...state,
        scenes: {
          ...state.scenes,
          [action.payload.currentScene]: {
            ...state.scenes[action.payload.currentScene],
            [action.payload.key]: action.payload.actualload,
          },
        },
      }
    case actions.SET_AGENDA:
      return {
        ...state,
        agenda: {
          ...state.agenda,
          [action.payload.currentAgenda]: {
            ...state.agenda[action.payload.currentAgenda],
            agendaItems: action.payload.actualload,
          },
        },
      }
    default:
      return state
  }
}
