import React from 'react'
import { Row, Col, Tooltip } from 'antd'
import { Popup } from 'react-chat-elements'
import {
  LeftOutlined,
  VideoCameraFilled,
  // PoweroffOutlined,
  // IdcardOutlined,
} from '@ant-design/icons'
import { connect } from 'react-redux'
import moment from 'moment'
import md5 from 'md5'
import actions from 'redux/virtual/chat/actions'
import '../ChatContent/react-chat-elements.modified.css'
import ChatAvatar from '../ChatAvatar'
import MeetingInvite from '../MeetingInvite'
import VisitingCard from '../VisitingCard'
import styles from '../../style.module.scss'

/* eslint-disable no-lonely-if */
const mapStateToProps = ({ Chat, virtualUser }) => ({ Chat, virtualUser })
@connect(mapStateToProps)
class ChatHeader extends React.Component {
  state = {
    showPopup: false,
    // showExit: false,
    showVisiting: false,
  }

  goBack = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentChat: '',
        currentScreen: 'home',
      },
    })
  }

  popmeup = () => {
    this.setState({ showPopup: true })
  }

  popmvisiteup = () => {
    this.setState({ showVisiting: true })
  }

  // popexit = () => {
  //   this.setState({ showExit: true })
  // }

  setMeeting = () => {
    const {
      Chat: { pubnub, currentChat },
      virtualUser: { id },
      dispatch,
    } = this.props
    const theRoomID = md5(`${id}-jitsi`)
    const currentDateinEPOCH = moment(new Date()).valueOf()
    const actualRoomName = `${theRoomID}-${currentDateinEPOCH}`
    dispatch({
      type: actions.SEND_MESSAGE,
      payload: {
        pubnub,
        message: {
          type: 'videomeet',
          publisher: id,
          content: <MeetingInvite id={actualRoomName} />,
          data: actualRoomName,
        },
        chatWith: currentChat,
      },
    })
  }

  sendVisitingCard = () => {
    const {
      Chat: { pubnub, currentChat },
      virtualUser: {
        id,
        name,
        designation,
        organization,
        avatarType,
        avatarData,
        avatarColor,
        email,
        number,
      },
      dispatch,
    } = this.props
    // const avatar =
    //   avatarType === 'text'
    //     ? `https://via.placeholder.com/100x100.png/${avatarColor.substring(
    //         1,
    //       )}/fff?text=${avatarData}`
    //     : avatarData
    const userData = {
      name,
      designation,
      organization,
      avatarType,
      avatarData,
      avatarColor,
      email,
      number,
    }
    dispatch({
      type: actions.SEND_MESSAGE,
      payload: {
        pubnub,
        message: {
          type: 'visitingcard',
          publisher: id,
          content: <VisitingCard userData={userData} />,
          userData,
        },
        chatWith: currentChat,
      },
    })
  }

  closeChat = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'virtualSettings/CHANGE_SETTING',
      payload: {
        setting: 'isSidebarOpen',
        value: false,
      },
    })
  }


  exitChat = () => {
    const {
      Chat: { currentChat },
      dispatch,
    } = this.props
    dispatch({
      type: actions.UNASSIGN_EXHIBITOR,
      payload: {
        currentChat,
      },
    })
  }

  render() {
    const {
      Chat: { currentChat, channelData, allUsers, userType, visitedExhibitors },
      // virtualUser: { roles },
    } = this.props

    let currentChatMeta = {}
    if (Object.prototype.hasOwnProperty.call(channelData, currentChat)) {
      currentChatMeta = channelData[currentChat].userMeta
    } else {
      if (userType === 'business') {
        currentChatMeta = visitedExhibitors[currentChat]
      } else {
        currentChatMeta = allUsers[currentChat]
      }
    }
    // const currentChatMeta = Object.prototype.hasOwnProperty.call(channelData, currentChat) ? channelData[currentChat].userMeta : allUsers[currentChat]
    const { showPopup, showVisiting } = this.state
    return (
      <>
        <Row justify="space-around" align="middle" className={styles.header}>
          <Col span={2} className="text-center">
            <LeftOutlined onClick={this.goBack} />
          </Col>
          <Col span={4} className="text-center">
            <ChatAvatar
              name={currentChatMeta.name}
              avatarType={currentChatMeta.avatar_type}
              avatarData={currentChatMeta.avatar_data}
              avatarColor={currentChatMeta.avatar_color}
              loginStatus={currentChatMeta.login_status}
              shape="square"
              size={35}
            />
          </Col>
          <Col span={12}>
            <h5 className={styles.headerName}>{currentChatMeta.name}</h5>
          </Col>
          {/* <Col span={2}>
            <Tooltip title="Visiting Card">
              <IdcardOutlined onClick={this.popmvisiteup} />
            </Tooltip>
          </Col> */}
          <Col span={2} className="text-center">
            <Tooltip title="Video Call">
              <VideoCameraFilled onClick={this.popmeup} />
            </Tooltip>
          </Col>
          <Col span={2} className="text-center">
            <Tooltip title="Close Chat" placement="bottomLeft">
              <button className={`${styles.close} fa fa-times`} onClick={this.closeChat} type="button" />
            </Tooltip>
          </Col>
          {/* {roles.includes('ROLE_EXHIBITOR') ? (
            <Col span={2} className="text-center">
              <Tooltip title="Close Chat">
                <PoweroffOutlined onClick={this.popexit} />
              </Tooltip>
            </Col>
          ) : (
            ''
          )} */}
        </Row>

        {/* <Popup
          show={showExit}
          header={
            <h3>
              <b>Close Chat with User</b>
            </h3>
          }
          headerButtons={[
            {
              type: 'transparent',
              color: 'black',
              text: (
                <h4>
                  <b>X</b>
                </h4>
              ),
              onClick: () => {
                this.setState({ showExit: false })
              },
            },
          ]}
          text={`This will close chat with ${currentChatMeta.name}. You'll not be able to see the messages sent to the user, once you log out. But this will allow another exhibitor to be assigned to the user.`}
          footerButtons={[
            {
              color: 'white',
              backgroundColor: '#1890ff',
              text: 'Cancel',
              onClick: () => {
                this.setState({ showExit: false })
              },
            },
            {
              color: 'white',
              backgroundColor: '#ff4d4f',
              text: 'Close Chat',
              onClick: () => {
                this.setState({ showExit: false })
                this.exitChat()
              },
            },
          ]}
        /> */}

        <Popup
          show={showPopup}
          header={
            <h3>
              <b>Create Video Meet</b>
            </h3>
          }
          headerButtons={[
            {
              type: 'transparent',
              color: 'black',
              text: (
                <h4>
                  <b>X</b>
                </h4>
              ),
              onClick: () => {
                this.setState({ showPopup: false })
              },
            },
          ]}
          text={`This will send a meeting invite to ${currentChatMeta.name}. You can start the meeting anytime you want using the same invitation.`}
          footerButtons={[
            {
              color: 'white',
              backgroundColor: '#ff4d4f',
              text: 'Cancel',
              onClick: () => {
                this.setState({ showPopup: false })
              },
            },
            {
              color: 'white',
              backgroundColor: '#1890ff',
              text: 'Send Invite',
              onClick: () => {
                this.setState({ showPopup: false })
                this.setMeeting()
              },
            },
          ]}
        />

        <Popup
          show={showVisiting}
          header={
            <h3>
              <b>Send Visiting Card</b>
            </h3>
          }
          headerButtons={[
            {
              type: 'transparent',
              color: 'black',
              text: (
                <h4>
                  <b>X</b>
                </h4>
              ),
              onClick: () => {
                this.setState({ showVisiting: false })
              },
            },
          ]}
          text={`This visiting card will be sent to ${currentChatMeta.name}. Kindly approve to send the visiting card.`}
          footerButtons={[
            {
              color: 'white',
              backgroundColor: '#ff4d4f',
              text: 'Cancel',
              onClick: () => {
                this.setState({ showVisiting: false })
              },
            },
            {
              color: 'white',
              backgroundColor: '#1890ff',
              text: 'Send Visiting Card',
              onClick: () => {
                this.setState({ showVisiting: false })
                this.sendVisitingCard()
              },
            },
          ]}
        />
      </>
    )
  }
}

export default ChatHeader
