import API from '../config/api'

export async function getGifts(data) {
  return API.get('fetchGifts', {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function redeemGifts(data) {
  if (data.giftid === 'donate') {
    return API.get(`donatePoints`, {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    })
  }
  return API.get(`redeemGift/${data.giftid}`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}
