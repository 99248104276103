import React from 'react'
// import { Modal, Row, Col, Tooltip } from 'antd'
import { connect } from 'react-redux'
import {isMobile} from 'react-device-detect';
// import VideoCover from 'react-video-cover'
// import { CloseCircleOutlined } from '@ant-design/icons'
// import ReactPlayer from 'react-player'
// import { Scrollbars } from 'react-custom-scrollbars'
// import actions from 'redux/virtual/user/actions'

import './tutvideo.less'

const mapStateToProps = ({ virtualUser }) => ({ virtualUser })

@connect(mapStateToProps)
class EntranceVideo extends React.Component {
  constructor(props){
    super(props)

    document.getElementById("entranceVideo").style.display = "block";
    if(!isMobile){
      document.getElementById("entranceVideo").style.marginTop = "48px"
      document.getElementById("skipbutton").style.bottom = "calc(2rem + 48px)"
    }
    const entranceVidRef = document.getElementById("actualEntranceVideo");
    entranceVidRef.play()

    const skipButtonRef = document.getElementById("skipbutton");
    skipButtonRef.onclick = () => {
      document.getElementById("entranceVideo").style.display = "none";
      entranceVidRef.pause()
      entranceVidRef.removeAttribute('src'); // empty source
      entranceVidRef.load();
      props.dispatch({
        type: 'virtaluser/SET_STATE',
        payload: {
          isInstructionScreenVisible: true,
          isEntranceVideoVisible: false,
          isEntranceVideoVisible2: true
        }
      })
    }

    entranceVidRef.onended = ()=>{
      document.getElementById("entranceVideo").style.display = "none";
      entranceVidRef.pause()
      entranceVidRef.removeAttribute('src'); // empty source
      entranceVidRef.load();

      props.dispatch({
        type: 'virtaluser/SET_STATE',
        payload: {
          isInstructionScreenVisible: true,
          isEntranceVideoVisible: false,
          isEntranceVideoVisible2: true
        }
      })
    }
    // setTimeout(()=>{
    //   document.getElementById("entranceVideo").style.display = "none";
    //   entranceVidRef.pause()
    //   entranceVidRef.removeAttribute('src'); // empty source
    //   entranceVidRef.load();
    // },2151000)
  }

  // state = {
  //   visible: "block"
  // }

  // componentDidMount = () =>{
  //   this.videoReference.load()
  //   this.videoReference.play()
  //   console.log("we are in mount")
  // }

  render() {
    // const {
    //   visible,
    // } = this.state
    return (
      // <div className="video-container" style={{display: visible }}>
      //   <VideoCover
      //     videoOptions={{
      //       src: "https://wiz365.s3.amazonaws.com/Lobby+Animation-1.mp4",
      //       ref: videoRef => {
      //         this.videoReference = videoRef
      //       },
      //     }}
          
      //     style={{display: visible }}
      //   />
      // </div>
      <></>
    )
  }
}

export default EntranceVideo
