import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Menu, Layout } from 'antd'
import store from 'store'
import { Scrollbars } from 'react-custom-scrollbars'
import agendaActions from 'redux/virtual/agenda/actions'
import chatActions from 'redux/virtual/chat/actions'
import userActions from 'redux/virtual/user/actions'
import eshopActions from 'redux/virtual/eshop/actions'
import _ from 'lodash'
import actions from 'redux/virtual/event/actions'
import styles from './style.module.scss'

const { Sider } = Layout
const { SubMenu, Divider } = Menu

const mapStateToProps = ({ virtualMenu, virtualSettings, virtualUser, virtualEvent, Chat }) => ({
  menuData: virtualMenu.menuTopData,
  isVirtualLightTheme: virtualSettings.isVirtualLightTheme,
  isSidebarOpen: virtualSettings.isSidebarOpen,
  avatarType: virtualUser.avatarType,
  avatarData: virtualUser.avatarData,
  avatarColor: virtualUser.avatarColor,
  name: virtualUser.name,
  designation: virtualUser.designation,
  organization: virtualUser.organization,
  isAgendaOpen: virtualSettings.isAgendaOpen,
  isSpeakersOpen: virtualSettings.isSpeakersOpen,
  isBriefcaseOpen: virtualSettings.isBriefcaseOpen,
  isLeadsOpen: virtualSettings.isLeadsOpen,
  isMeetingsOpen: virtualSettings.isMeetingsOpen,
  isBriefCaseCalled: virtualEvent.isBriefCaseCalled,
  isLeaderBoardOpen: virtualEvent.isLeaderBoardOpen,
  isEShopOpen: virtualEvent.isEShopOpen,
  isLeaderBoardCalled: virtualEvent.isLeaderBoardCalled,
  theHistory: virtualEvent.theHistory,
  chatvisitedonce: Chat.chatvisitedonce,
  currentTab: Chat.currentTab,
  visitedExhibitors: Chat.visitedExhibitors,
})

@withRouter
@connect(mapStateToProps)
class MenuLeft extends React.Component {
  state = {
    selectedKeys: store.get('app.virtualMenu.selectedKeys') || [],
    openedKeys: store.get('app.virtualMenu.openedKeys') || [],
  }

  componentWillMount() {
    this.setSelectedKeys(this.props)
  }

  componentWillReceiveProps(newProps) {
    if (newProps.isVirtualMenuCollapsed && !newProps.isVirtualMobileView) {
      this.setState({
        openedKeys: [],
      })
    }
    this.setSelectedKeys(newProps)
  }

  setSelectedKeys = props => {
    const { menuData } = this.props
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = _.find(flattenItems(menuData, 'children'), [
      'url',
      props.location.pathname,
    ])
    this.setState({
      selectedKeys: selectedItem ? [selectedItem.key] : [],
    })
  }

  onCollapse = (value, type) => {
    const { dispatch, isVirtualMenuCollapsed } = this.props
    if (type === 'responsive' && isVirtualMenuCollapsed) {
      return
    }

    dispatch({
      type: 'virtualSettings/CHANGE_SETTING',
      payload: {
        setting: 'isVirtualMenuCollapsed',
        value: !isVirtualMenuCollapsed,
      },
    })

    this.setState({
      openedKeys: [],
    })
  }

  onOpenChange = openedKeys => {
    store.set('app.virtualMenu.openedKeys', openedKeys)
    this.setState({
      openedKeys,
    })
  }

  handleClick = e => {
    const {
      dispatch,
      isSidebarOpen,
      isAgendaOpen,
      isBriefcaseOpen,
      isLeadsOpen,
      isMeetingsOpen,
      isBriefCaseCalled,
      theHistory,
      chatvisitedonce,
      currentTab,
      isSpeakersOpen,
      visitedExhibitors,
      isLeaderBoardCalled,
      isLeaderBoardOpen,
      isEShopOpen,
    } = this.props
    store.set('app.virtualMenu.selectedKeys', [e.key])
    if (e.key === 'sidebar') {
      if (!chatvisitedonce && currentTab === 'attendees') {
        dispatch({
          type: 'chat/GET_ALL_USERS',
        })
      }
      dispatch({
        type: 'virtualSettings/CHANGE_SETTING',
        payload: {
          setting: 'isSidebarOpen',
          value: !isSidebarOpen,
        },
      })
      return
    }
    if (e.key === 'back') {
      const tempHistory = theHistory
      tempHistory.pop()
      dispatch({
        type: actions.SET_STATE,
        payload: {
          theHistory: tempHistory,
        },
      })
      dispatch({
        type: actions.FILTER_SCENE,
        payload: {
          id: tempHistory[tempHistory.length - 1],
        },
      })

      return
    }
    if (e.key === 'logout') {
      dispatch({
        type: 'virtaluser/LOGOUT',
      })
      return
    }
    if (e.key === 'sidebar') {
      dispatch({
        type: 'virtualSettings/CHANGE_SETTING',
        payload: {
          setting: 'isSidebarOpen',
          value: !isSidebarOpen,
        },
      })
      return
    }
    if (e.key === 'agendaImage') {
      dispatch({
        type: 'virtualEvent/SET_STATE',
        payload: {
          imagePopOpen: true,
          imagePopURL: 'resources/images/agenda.jpg'
        },
      })
      return
    }
    if (e.key === 'feedback') {
      dispatch({
        type: actions.SET_STATE,
        payload: {
          isSocialWallOpen: true,
          socialWallURL: 'https://form.jotform.com/212102350746040',
          hasWidgetPoints: false,
          widgetPoints: 0,
          widgetID: 'feedback'
        },
      })
      return
    }

    if (e.key === 'pointsystem') {
      dispatch({
        type: 'virtualEvent/SET_STATE',
        payload: {
          imagePopOpen: true,
          imagePopURL: 'resources/images/pointingsystem.jpg'
        },
      })
      return
    }
    
    if (e.key === 'agenda') {
      dispatch({
        type: agendaActions.GET_AGENDA,
        payload: {},
      })
      dispatch({
        type: 'virtualSettings/CHANGE_SETTING',
        payload: {
          setting: 'isAgendaOpen',
          value: !isAgendaOpen,
        },
      })
      return
    }
    if (e.key === 'speakers') {
      console.log('clicked on speakers')
      console.log(isSpeakersOpen)
      dispatch({
        type: agendaActions.GET_SPEAKERS,
        payload: {},
      })
      dispatch({
        type: 'virtualSettings/CHANGE_SETTING',
        payload: {
          setting: 'isSpeakersOpen',
          value: !isSpeakersOpen,
        },
      })
      return
    }
    if (e.key === 'helpdesk') {
      const helpdeskID = '38e5c1b-4b1d-df52-308a-177665c1ce7'
      const tempvisitedExhibitors = visitedExhibitors

      tempvisitedExhibitors[helpdeskID] = {
        login_status: true,
        name: 'GBS Helpdesk',
        avatar_data:
          'https://storage.googleapis.com/wiz365/d67c8af7-709b-11eb-ae70-2fc0e19a87cf.jpg',
        avatar_type: 'image',
      }

      dispatch({
        type: chatActions.SET_STATE,
        payload: {
          currentChat: helpdeskID,
          currentScreen: 'chatWindow',
          userType: 'business',
          currentTab: 'messages',
          visitedExhibitors: tempvisitedExhibitors,
        },
      })

      dispatch({
        type: 'virtualSettings/CHANGE_SETTING',
        payload: {
          setting: 'isSidebarOpen',
          value: !isSidebarOpen,
        },
      })
      return
    }
    if (e.key === 'directory') {
      dispatch({
        type: actions.SET_STATE,
        payload: {
          directoryVisible: true,
        },
      })
      return
    }
    if (e.key === 'visitedstalls') {
      dispatch({
        type: userActions.SET_STATE,
        payload: {
          isViewedStallsOpen: true,
        },
      })
      return
    }
    if (e.key === 'briefcase') {
      if (!isBriefCaseCalled) {
        dispatch({
          type: actions.GET_BRIEFCASE,
          payload: {},
        })
      }
      dispatch({
        type: 'virtualSettings/CHANGE_SETTING',
        payload: {
          setting: 'isBriefcaseOpen',
          value: !isBriefcaseOpen,
        },
      })
      return
    }
    if (e.key === 'leaderBoard') {
      console.log('clicked on Leaderboard')
      console.log(isLeaderBoardOpen)
      if (!isLeaderBoardCalled) {
        dispatch({
          type: actions.GET_LEADERBOARD,
          payload: {},
        })
      }
      dispatch({
        type: actions.SET_STATE,
        payload: {
          isLeaderBoardOpen: !isLeaderBoardOpen,
        },
      })
      return
    }
    if (e.key === 'eShop') {
      dispatch({
        type: actions.GET_LEADERBOARD,
        payload: {},
      })
      dispatch({
        type: eshopActions.GET_GIFTS,
        payload: {},
      })
      dispatch({
        type: actions.SET_STATE,
        payload: {
          isEShopOpen: !isEShopOpen,
        },
      })
      return
    }
    if (e.key === 'leads') {
      dispatch({
        type: 'virtualSettings/CHANGE_SETTING',
        payload: {
          setting: 'isLeadsOpen',
          value: !isLeadsOpen,
        },
      })
      return
    }
    if (e.key === 'meeting') {
      if (!isMeetingsOpen) {
        dispatch({
          type: 'meetings/GET_MEETING',
        })
      }
      dispatch({
        type: 'virtualSettings/CHANGE_SETTING',
        payload: {
          setting: 'isMeetingsOpen',
          value: !isMeetingsOpen,
        },
      })
      return
    }
    if (e.key === 'home') {
      dispatch({
        type: actions.GET_DEFAULT_SCENE,
      })
      return
    }
    // if (e.key === 'navigation') {
    //   dispatch({
    //     type: actions.FILTER_NAVIGATION_SCENE,
    //   })
    //   return
    // }
    // if (e.key === 'back') {
    //   if(lastPath != "")
    //   {
    //     dispatch({
    //       type: actions.GET_DEFAULT_SCENE
    //     })
    //   }

    //   return
    // }
    this.setState({
      selectedKeys: [e.key],
    })
  }

  generateMenuItems = () => {
    const { menuData = [] } = this.props
    const generateItem = item => {
      const { key, title, url, icon, disabled, pro } = item
      if (item.divider) {
        return <Divider key={Math.random()} />
      }
      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled}>
            {item.target ? (
              <a href={url} target={item.target} rel="noopener noreferrer">
                {icon && <span className={`${icon} ${styles.icon} icon-collapsed-hidden`} />}
                <span className={styles.title}>{title}</span>
                {pro && (
                  <span className="badge badge-primary badge-collapsed-hidden ml-2">PRO</span>
                )}
              </a>
            ) : (
              <Link to={url}>
                {icon && <span className={`${icon} ${styles.icon} icon-collapsed-hidden`} />}
                <span className={styles.title}>{title}</span>
                {pro && (
                  <span className="badge badge-primary badge-collapsed-hidden ml-2">PRO</span>
                )}
              </Link>
            )}
          </Menu.Item>
        )
      }
      return (
        <Menu.Item key={key} disabled={disabled}>
          {icon && <span className={`${icon} ${styles.icon} icon-collapsed-hidden`} />}
          <span className={styles.title}>{title}</span>
          {pro && <span className="badge badge-primary badge-collapsed-hidden ml-2">PRO</span>}
        </Menu.Item>
      )
    }

    const generateSubmenu = items =>
      items.map(menuItem => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              <span className={styles.title}>{menuItem.title}</span>
              {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />}
            </span>
          )
          return (
            <SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </SubMenu>
          )
        }
        return generateItem(menuItem)
      })

    return menuData.map(menuItem => {
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            <span className={styles.title}>{menuItem.title}</span>
            {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />}
          </span>
        )
        return (
          <SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </SubMenu>
        )
      }
      return generateItem(menuItem)
    })
  }

  render() {
    const { selectedKeys, openedKeys } = this.state
    const { isVirtualMobileView, isVirtualMenuCollapsed, isVirtualLightTheme } = this.props
    const menuSettings = isVirtualMobileView
      ? {
          width: 256,
          collapsible: false,
          collapsed: false,
          onCollapse: this.onCollapse,
        }
      : {
          width: 256,
          collapsible: true,
          collapsed: isVirtualMenuCollapsed,
          onCollapse: this.onCollapse,
          breakpoint: 'lg',
        }

    const menu = this.generateMenuItems()

    return (
      <Sider
        {...menuSettings}
        className={isVirtualLightTheme ? `${styles.menu} ${styles.light}` : styles.menu}
      >
        <div className={styles.logo}>
          <div className={`${styles.logoContainer} text-center`}>
            <img
              src={`resources/images/logo-inverse${menuSettings.collapsed ? '-mobile' : ''}.png`}
              alt=""
            />
          </div>
        </div>
        <Scrollbars
          className={isVirtualMobileView ? styles.scrollbarMobile : styles.scrollbarDesktop}
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                width: '4px',
                borderRadius: 'inherit',
                backgroundColor: '#c5cdd2',
                left: '1px',
              }}
            />
          )}
          autoHide
        >
          <Menu
            theme={isVirtualLightTheme ? 'light' : 'dark'}
            onClick={this.handleClick}
            selectedKeys={selectedKeys}
            openKeys={openedKeys}
            onOpenChange={this.onOpenChange}
            mode="inline"
            className={styles.navigation}
          >
            {menu}
          </Menu>
        </Scrollbars>
      </Sider>
    )
  }
}

export default MenuLeft
