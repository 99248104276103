import React from 'react'
import { Modal, Row, Col } from 'antd'
import { connect } from 'react-redux'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'
import actions from 'redux/virtual/user/actions'
import EditProfileForm from './EditProfileForm'

import './editprofile.less'

const mapStateToProps = ({ virtualUser }) => ({ virtualUser })

@connect(mapStateToProps)
class EditProfile extends React.Component {
  // constructor(props) {
  //   super(props)
  //   props.dispatch({
  //     type: actions.GET_AGENDA,
  //     payload: {},
  //   })
  // }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isEditProfileOpen: false,
        // isTutorialVideoVisible: true
      },
    })
  }

  render() {
    const {
      virtualUser: { isEditProfileOpen },
    } = this.props
    return (
      <div>
        <Modal
          visible={isEditProfileOpen}
          style={{
            top: 0,
            display: 'flex',
          }}
          wrapClassName="editProfileModal"
          maskClosable={false}
          mask
          width="40rem"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          maskStyle={{backgroundColor: "rgba(15, 12, 41, 0.93)"}}
          closeIcon={<CloseCircleOutlined style={{ color: 'white', fontSize: '25px' }} />}
          destroyOnClose
        >
          <Row id="modalHeader" className="modalHeader text-center">
            <Col span={24}>
              <h3>Change Profile Picture</h3>
            </Col>
          </Row>
          <Row id="modalContent" className="modalContent">
            <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 15rem)">
              <Col span={24}>
                <EditProfileForm />
              </Col>
            </Scrollbars>
          </Row>
        </Modal>
      </div>
    )
  }
}

export default EditProfile
