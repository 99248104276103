import React from 'react'
import { all, call, takeEvery, put, select } from 'redux-saga/effects'
import { fetchEnteries, entryUpload, removeLikeEntry, likeEntry } from 'services/virtual/contest'
import VideoShowcase from 'components/LayoutComponents/Virtual/Contest/VideoShowcase'
// import VideoThumbnail from 'react-video-thumbnail';
import { message } from 'antd'
import actions from './actions'

/* eslint-disable no-unused-vars,no-plusplus,no-return-assign,no-sequences */
export function* FETCH_CONTEST_ENTERIES(data) {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
      contest: { currentContestID, theImageSet, contestEnteries },
    } = state
    const contest = yield call(fetchEnteries, {
      token: accessToken,
      widgetid: currentContestID,
      currentPage: data.payload.currentPage,
    })
    if (contest.status === 200) {
      const theFinalData = contest.data
      const tempImageSet = theImageSet
      let initialCounter = theImageSet.length > 0 ? theImageSet.length : 0
      Object.entries(theFinalData).map(key => {
        const extension = key[1].file_url
          .substring(key[1].file_url.lastIndexOf('.') + 1)
          .toLowerCase()
        if (extension === 'mp4') {
          tempImageSet.push({
            original: key[1].file_url,
            thumbnail: key[1].thumbnailUrl,
            originalIndex: initialCounter++,
            entry_id: key[1].entry_id,
            description: key[1].entry_id,
            // renderThumbInner: () => {return <VideoThumbnail videoUrl={key[1].file_url} width="100%" height="100%" />},
            renderItem: () => {
              return <VideoShowcase url={key[1].file_url} thumbnailurl={key[1].thumbnailUrl} />
            },
          })
        } else {
          tempImageSet.push({
            original: key[1].file_url,
            thumbnail: key[1].thumbnailUrl,
            description: key[1].entry_id,
            // originalIndex: key[0],
            originalIndex: initialCounter++,
            entry_id: key[1].entry_id,
          })
        }

        return ''
      })

      yield put({
        type: actions.SET_STATE,
        payload: {
          contestEnteries: contestEnteries.concat(theFinalData),
          theImageSet: tempImageSet,
        },
      })
    }
  } catch (err) {
    console.log(err)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* ENTRY_UPLOAD(data) {
  try {
    const state = yield select()
    const {
      contest: { currentContestID, theImageSet, contestEnteries },
      virtualUser: { accessToken, id, name },
    } = state
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
        data.payload.onProgress({ percent: percentCompleted })
      },
    }

    const result = yield call(entryUpload, {
      file: data.payload.file,
      thumbnail: data.payload.thumbnail,
      config,
      currentContestID,
      token: accessToken,
    })
    console.log('Entry Submitted')
    console.log(result)
    if (result.status === 200) {
      const tempImageSet = theImageSet
      const tempcontestEnteries = contestEnteries
      const fileURL = result.data.fileUrl
      const extension = fileURL.substring(fileURL.lastIndexOf('.') + 1).toLowerCase()

      tempcontestEnteries.push({
        file_url: fileURL,
        uploaded_by_name: name,
        total_likes: 0,
        liked_by_user: false,
        uploaded_by_id: id,
        thumbnailUrl: result.data.thumbnailUrl,
        entry_id: result.data.entryId,
      })

      if (extension === 'mp4') {
        tempImageSet.push({
          original: fileURL,
          thumbnail: result.data.thumbnailUrl,
          originalIndex: contestEnteries.length - 1,
          entry_id: result.data.entryId,
          renderItem: () => {
            return <VideoShowcase url={fileURL} thumbnailurl={result.data.thumbnailUrl} />
          },
        })
      } else {
        tempImageSet.push({
          original: fileURL,
          thumbnail: result.data.thumbnailUrl,
          originalIndex: contestEnteries.length - 1,
          entry_id: result.data.entryId,
        })
      }

      yield put({
        type: actions.SET_STATE,
        payload: {
          theImageSet: tempImageSet,
          contestEnteries: tempcontestEnteries,
        },
      })
      data.payload.onSuccess('ok')
      message.success('Congratulations! Your entry has been succesfully submitted.')
    } else {
      data.payload.onError({ event: result.statusText })
      message.error(
        'Oops! There seems to be an issue with submitting your entry. Check your internet and try again',
      )
    }
  } catch (err) {
    data.payload.onError({ event: err.response.data.message })
    console.log(err.response)
    message.error(err.response.data.message)
    message.error(
      'Oops! There seems to be an issue with submitting your entry. Check your internet and try again',
    )
  }
}

export function* REMOVE_LIKE(data) {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state
    yield call(removeLikeEntry, { token: accessToken, entryID: data.payload.entryID })
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* ADD_LIKE(data) {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state
    yield call(likeEntry, { token: accessToken, entryID: data.payload.entryID })
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_CONTEST_ENTERIES, FETCH_CONTEST_ENTERIES),
    takeEvery(actions.ENTRY_UPLOAD, ENTRY_UPLOAD),
    takeEvery(actions.ADD_LIKE, ADD_LIKE),
    takeEvery(actions.REMOVE_LIKE, REMOVE_LIKE),
  ])
}
