import React from 'react'
import { Row, Col } from 'antd'

class DonationDetails extends React.Component {
  render() {
    const { points } = this.props
    return (
      <Row>
        <Col span={24}>
          <h2 style={{ textAlign: 'center' }}>Donate Your Coin</h2>
          <br />
          <p>Hey There!</p>
          <p>
            This is the donation zone. If you click the donate button below, you will be donating
            all your coins. We will double your coin value and will give equivalent value voucher to
            an NGO.
          </p>
          <p>Accumulate more coins to make an even bigger donation to the NGO.</p>
          {points > 0 ? (
            ''
          ) : (
            <p style={{ color: 'red' }}>
              You have not sufficient coin for donation.please accumulate more coins to make
              donation.
            </p>
          )}
        </Col>
      </Row>
    )
  }
}

export default DonationDetails
