import React from 'react'
import { Modal, Row, Col /* Skeleton */ } from 'antd'
import { connect } from 'react-redux'
import { CloseCircleTwoTone } from '@ant-design/icons'
import Scrollbars from 'react-custom-scrollbars'
import eventActions from 'redux/virtual/event/actions'
import actions from 'redux/virtual/eshop/actions'
import ItemsLevel from './ItemLevel'
import './eshop.less'
import DonationDetails from './DonationDetails'
/* eslint-disable react/jsx-indent, camelcase,radix, no-unneeded-ternary, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
const { confirm } = Modal

const mapStateToProps = ({ virtualEvent, eshop }) => ({
  virtualEvent,
  eshop,
})

@connect(mapStateToProps)
class EShop extends React.Component {
  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: eventActions.SET_STATE,
      payload: {
        isEShopOpen: false,
      },
    })
    dispatch({
      type: actions.SET_STATE,
      payload: {
        giftsData: {},
        loading: false,
      },
    })
  }

  handleDonation = points => {
    const { dispatch } = this.props
    const disableDonate = points > 0 ? false : true
    confirm({
      icon: '',
      content: <DonationDetails points={points} />,
      okText: 'Donate',
      className: 'eshopRedeemModel',
      okButtonProps: { className: 'okButton', disabled: disableDonate },
      cancelButtonProps: { className: 'CancelBtn' },
      confirmLoading: true,
      width: '50vw',
      onOk() {
        dispatch({
          type: actions.REDEEM_GIFTS,
          payload: {
            giftid: 'donate',
          },
        })
      },
      onCancel() {},
    })
  }

  render() {
    const {
      virtualEvent: { isEShopOpen, leaderBoardData },
      eshop: { giftsData },
    } = this.props
    const { gift_redeem } = giftsData
    const points = leaderBoardData.userScore !== undefined ? leaderBoardData.userScore.points : 0

    return (
      <div>
        <Modal
          visible={isEShopOpen}
          style={{
            top: 0,
            // display: 'flex',
            maxHeight: '100vh',
            overflow: 'hidden',
          }}
          wrapClassName="eshopModel"
          maskClosable={false}
          mask
          width="60vw"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={<CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: '25px' }} />}
          // destroyOnClose
        >
          <ul className="circles">
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
          </ul>
          <Row gutter={24} id="modalContent" className="modalContent">
            <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 17rem)">
              <Col span={24}>
                <Row gutter={24}>
                  <Col span={24}>
                    <div className="eshop-points-row">
                      <span
                        className="eshop-donation-container"
                        style={{
                          filter: gift_redeem ? 'grayscale(1)' : 'grayscale(0)',
                          pointerEvents: gift_redeem ? 'none' : '',
                        }}
                      >
                        <img
                          onClick={() => this.handleDonation(points)}
                          src="https://i.ibb.co/4fw95vC/donatebutton-min.png"
                          alt="Donation"
                        />
                      </span>
                      <span className="eshop-points-container">
                        <img src="https://i.ibb.co/2tvnS0R/coin-emoji.png" alt="coin" />
                        <p>{points > 0 ? `${points} Coins` : 'NA'}</p>
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>
                    <div className="store-header">
                      <h1>STORE</h1>
                    </div>
                  </Col>
                </Row>
                <br />
                <Row gutter={24}>
                  <Col span={24}>
                    <ItemsLevel points={points > 0 ? points : 0} />
                  </Col>
                </Row>
              </Col>
            </Scrollbars>
          </Row>
        </Modal>
      </div>
    )
  }
}

export default EShop
