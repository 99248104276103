import localeAntd from 'antd/es/locale/hi_IN'

const messages = {
  'virtualMenu.home': 'घर',
  'virtualMenu.back': 'वापस',
  'virtualMenu.agenda': 'कार्यसूची',
  'virtualMenu.chat': 'चैट',
  'virtualMenu.navigation': 'पथ प्रदर्शन',
  'virtualMenu.briefcase': 'ब्रीफ़केस',
  'virtualMenu.speakers': 'वक्ताओं',
  'virtualMenu.helpdesk': 'सहायता केंद्र',
  'virtualMenu.mypoints': 'मेरे अंक',
  'virtualMenu.meetings': 'बैठक',
  'virtualMenu.leaderboard': 'लीडरबोर्ड',
  'virtualMenu.eshop': 'E दुकान',
  'topMenu.logout': 'लॉग आउट',
  'chat.heading': 'चैट',
  'chat.subheading': 'नई बातचीत शुरू करें',
  'chat.attendees': 'सहभागी',
  'chat.requests': 'अनुरोध',
  'chat.messages': 'संदेश',
  'chat.placeholder': 'यहां अपना संदेश लिखें',
  'streamFeature.heading': 'इंटरेक्शन जोन',
  'streamFeature.liveChat': 'सीधी बातचीत',
  'streamFeature.qna': 'प्रश्नोत्तर पैनल',
  'streamFeature.polling': 'लाइव मतदान',
  'streamFeature.qnaplaceholder': 'यहां अपना प्रश्न पूछें। प्रासंगिक रहो।',
  'general.resourceCenter': 'संसाधन केन्द्र',
  'bottomMenu.groupVideo': 'लाइव बैठक',
  'bottomMenu.enable': 'निचला नेविगेशन सक्षम करें',
  'bottomMenu.chat': 'चैट',
  'bottomMenu.intro': 'परिचय',
  'bottomMenu.meeting': 'मुलाकात',
  'bottomMenu.dropCard': 'ड्रॉप कार्ड',
  'bottomMenu.exhibitors': 'प्रदर्शकों',
  'bottomMenu.socialMedia': 'सामाजिक मीडिया',
  'bottomMenu.share': 'शेयर',
  'bottomMenu.rating': 'रेटिंग',
  'bottomMenu.download': 'डाउनलोड',
}

export default {
  locale: 'ja-JP',
  localeAntd,
  messages,
}
