import API from '../config/api'

export async function getAllTasks(data) {
  return API.get(`getAllTasks`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function updateSectionScore(data) {
  return API.get(`updateSectionScore/${data.sectionID}`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function logout() {
  return ''
}
