import { all, call, takeEvery, put, select } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import {
  getDefaultScene,
  getSelectedScene,
  getSelectedAgenda,
  updateUserAnalytics,
  saveBriefcase,
  getBriefcase,
  removeBriefcase,
  sendBriefcaseEmail,
  saveLead,
  assignChair,
  leaveChair,
  getTopology,
  getLeaderBoard,
  fetchJukebox,
  updateUserScore,
  getDefaultURL
} from 'services/virtual/event'
import { pubnubPublish } from 'services/virtual/chat'
import { downloadDocument } from 'services/virtual/download'
import { message, notification } from 'antd'
import actions from './actions'
import chatActions from '../chat/actions'
import streamActions from '../streamfeature/actions'
import userActions from '../user/actions'

export function* GET_DEFAULT_SCENE() {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state
    const defaultScene = yield call(getDefaultScene, { token: accessToken })
    if (Object.keys(defaultScene.data).length > 0) {
      const idFinal = defaultScene.data.id
      yield put({
        type: actions.SET_STATE,
        payload: {
          homescreen: idFinal,
        },
      })
      yield put({
        type: actions.FILTER_SCENE,
        payload: {
          id: idFinal,
          currentPath: '',
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* FILTER_SCENE(data) {
  try {
    const state = yield select()
    const {
      Chat: { pubnub },
      virtualUser: { roles, accessToken, allRatings },
      streamFeature: { assignedSessions },
      agenda: { agendas },
      virtualEvent: {
        currenSceneAgenda,
        theHistory,
        scene,
        subscribedSessionChannels,
        scenePointsClaimed,
        // initialdollyStatus
      },
    } = state
    console.log('inside filter scene')
    console.log(data.payload)
    let result = {}
    if (Object.prototype.hasOwnProperty.call(data.payload, 'callScene')) {
      if (data.payload.callScene) {
        result = yield call(getSelectedScene, { id: data.payload.id, token: accessToken })
      } else {
        result.data = scene
      }
    } else {
      result = yield call(getSelectedScene, { id: data.payload.id, token: accessToken })
    }

    // const result = yield call(getSelectedScene, { id: data.payload.id, token: accessToken })
    // const result = yield call(getSelectedScene, { id: data.payload.id })

    if (Object.keys(result.data).length > 0) {
      let passBasedOnRules = false
      if (result.data.roleRestriction) {
        if (result.data.roles !== null && result.data.roles !== undefined) {
          if (result.data.roles.length > 0) {
            result.data.roles.forEach(element => {
              if (roles.includes(element)) {
                passBasedOnRules = true
              }
            })
          } else {
            passBasedOnRules = true
          }
        } else {
          passBasedOnRules = true
        }
      } else {
        passBasedOnRules = true
      }
      if (result.data.sceneToggle || roles.includes('ROLE_ADMIN')) {
        if (passBasedOnRules) {
          let pushPath = `/virtual/main?scene=${data.payload.id}`
          // yield put(push(pushPath))
          if (
            result.data.auditoriumStatus &&
            data.payload.sessionID !== null &&
            data.payload.sessionID !== undefined
          ) {
            pushPath += `&session=${data.payload.sessionID}`
          }
          yield put(push(pushPath))
          const tempHistory = theHistory
          if (theHistory[theHistory.length - 1] !== data.payload.id) {
            tempHistory.push(data.payload.id)
          }

          yield put({
            type: actions.SET_STATE,
            payload: {
              scene: result.data,
              theHistory: tempHistory,
            },
          })

          const dollyStatus = {}
          Object.entries(result.data.clickSections).map(key => {
            if (key[1].dollyactive) {
              console.log('looking dolly issue')
              console.log(key[1])
              dollyStatus[key[0]] = {
                display: 'none',
                autoplay: false,
                timing: Object.prototype.hasOwnProperty.call(key[1], 'dollyShotTime')
                  ? key[1].dollyShotTime
                  : 10000,
              }
            }
            return ''
          })

          console.log(dollyStatus)
          yield put({
            type: actions.SET_STATE,
            payload: {
              dollyStatus,
              initialdollyStatus: dollyStatus
            },
          })

          if (result.data.socialTableStatus) {
            yield put({
              type: chatActions.SUBSCRIBE_CHANNELS,
              payload: {
                channels: [data.payload.id],
                pubnub,
              },
            })
            yield put({
              type: actions.GET_NETWORK_TOPOLOGY,
            })
          }

          if (result.data.stallStatus) {
            if (Object.prototype.hasOwnProperty.call(allRatings, data.payload.id)) {
              if (!allRatings[data.payload.id].status) {
                const tempAllRatings = allRatings
                tempAllRatings[data.payload.id].status = true
                yield put({
                  type: userActions.SET_STATE,
                  payload: {
                    allRatings: tempAllRatings,
                  },
                })
              }
            }
          }

          if (subscribedSessionChannels.length !== 0) {
            yield put({
              type: chatActions.UNSUBSCRIBE_CHANNELS,
              payload: {
                channels: subscribedSessionChannels,
                pubnub,
              },
            })
          }

          console.log("checking social tabel")
          console.log(result.data.socialTableStatus)
          console.log(result.data)
          if (result.data.socialTableStatus) {
            yield put({
              type: actions.SET_STATE,
              payload: {
                isSocialTableInstructionScreenOpen: true
              },
            })
          }
          if (result.data.auditoriumStatus) {
            if (data.payload.sessionID !== null && data.payload.sessionID !== undefined) {
              yield put({
                type: actions.SET_STATE,
                payload: {
                  isSceneAgendaOpen: false,
                  sessionRender: true,
                  currentSession: data.payload.sessionID,
                  currentSessionURL: data.payload.currentSessionURL,
                },
              })

              /* Temp fix for Flipkart */
              const idToSubscribe = data.payload.sessionID
              // const idToSubscribe =  Object.keys(agendas).length !== 0 ? Object.keys(agendas)[0] : Object.keys(currenSceneAgenda)[0]
              const tempChannels = [
                idToSubscribe,
                `${idToSubscribe}-polling`,
                `${idToSubscribe}-reactions`,
              ]
              if (roles.includes('ROLE_MODERATOR')) {
                if (assignedSessions.includes(data.payload.sessionID)) {
                  tempChannels.push(`${idToSubscribe}-moderator`)
                }
              }

              yield put({
                type: chatActions.SUBSCRIBE_CHANNELS,
                payload: {
                  channels: tempChannels,
                  pubnub,
                },
              })

              yield put({
                type: actions.SET_STATE,
                payload: {
                  subscribedSessionChannels: tempChannels,
                },
              })

              let sessionDetails = {}
              if (Object.keys(agendas).length !== 0) {
                Object.entries(agendas).map(key => {
                  if (
                    Object.prototype.hasOwnProperty.call(key[1].agendaItems, data.payload.sessionID)
                  ) {
                    sessionDetails = key[1].agendaItems[data.payload.sessionID]
                  }
                  return ''
                })
              } else {
                Object.entries(currenSceneAgenda).map(key => {
                  if (
                    Object.prototype.hasOwnProperty.call(key[1].agendaItems, data.payload.sessionID)
                  ) {
                    sessionDetails = key[1].agendaItems[data.payload.sessionID]
                  }
                  return ''
                })
              }

              yield put({
                type: streamActions.SET_STATE,
                payload: {
                  currentSessionDetails: sessionDetails,
                  isSessionFullScreen: false,
                },
              })

              yield put({
                type: streamActions.FETCH_MESSAGES,
                payload: {
                  channels: tempChannels,
                  pubnub,
                },
              })

              yield put({
                type: streamActions.FETCH_POLL,
              })

              yield put({
                type: streamActions.SET_STATE,
                payload: {
                  channelID: '',
                  readStatus: 'read',
                  unreadCount: 0,
                  channelType: '',
                },
              })
            } else {
              const agenda = yield call(getSelectedAgenda, { id: data.payload.id })
              if (Object.keys(agenda.data).length > 0) {
                yield put({
                  type: actions.SET_STATE,
                  payload: {
                    currenSceneAgenda: agenda.data,
                    agendaLoading: false,
                    // isSceneAgendaOpen: true,
                  },
                })

                console.log('Lets check Local Agenda')
                const theBaseAgenda = agenda.data[Object.keys(agenda.data)[0]]
                const theBaseSession =
                  theBaseAgenda.agendaItems[Object.keys(theBaseAgenda.agendaItems)[0]]
                const id = Object.keys(theBaseAgenda.agendaItems)[0]
                const sceneselected = theBaseSession.assignedRoom
                const { url } = theBaseSession
                yield put({
                  type: actions.FILTER_SCENE,
                  payload: {
                    id: sceneselected,
                    navigated: true,
                    componentRendered: false,
                    sessionID: id,
                    currentSessionURL: url,
                    callScene: false,
                  },
                })
              }
            }
          } else {
            yield put({
              type: actions.SET_STATE,
              payload: {
                isSceneAgendaOpen: false,
                currenSceneAgenda: {},
                agendaLoading: true,
                sessionRender: false,
                currentSessionURL: '',
                currentSession: '',
              },
            })
          }

          if (Object.prototype.hasOwnProperty.call(data.payload, 'callScene')) {
            if (data.payload.callScene) {
              yield call(updateUserAnalytics, { id: data.payload.id, token: accessToken })
            }
          } else {
            yield call(updateUserAnalytics, { id: data.payload.id, token: accessToken })
          }

          if (result.data.leaderboardToggle) {
            if (!scenePointsClaimed.includes(data.payload.id)) {
              const claimData = yield call(updateUserScore, { id: data.payload.id, token: accessToken })
              if (claimData.status === 200) {
                notification.success({
                  message: 'Points Added',
                  duration: 5,
                  description:
                    'Points have been successfully added to the leaderboard.',
                })
                const tempScenePointsClaimed = scenePointsClaimed
                tempScenePointsClaimed.push(data.payload.id)
                yield put({
                  type: actions.SET_STATE,
                  payload: {
                    scenePointsClaimed: tempScenePointsClaimed,
                  },
                })
              }
            }
          }
        } else {
          notification.error({
            message: 'Access Restricted',
            description: result.data.roleRestrictionMessage,
            duration: 12,
          })
          yield put({
            type: actions.GET_DEFAULT_SCENE,
          })
          // yield put({
          //   type: actions.SET_STATE,
          //   payload: {
          //     dollyStatus: initialdollyStatus
          //   }
          // })
          
        }
      } else {
        notification.error({
          message: 'Entry Restricted',
          description: result.data.sceneToggleMessage,
          duration: 12,
        })
        yield put({
          type: actions.GET_DEFAULT_SCENE,
        })
      }
    }
  } catch (err) {
    console.log('inside filter scene error')
    console.log(err)
    console.log(err.response)
    // message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* GET_SPECIFIC_AGENDA(data) {
  try {
    const result = yield call(getSelectedScene, { id: data.payload.id })
    if (Object.keys(result.data).length > 0) {
      yield put(push(`/virtual/main?scene=${data.payload.id}`))
      yield put({
        type: actions.SET_STATE,
        payload: {
          scene: result.data,
        },
      })

      if (result.data.auditoriumStatus) {
        const agenda = yield call(getSelectedAgenda, { id: data.payload.id })
        if (Object.keys(agenda.data).length > 0) {
          yield put({
            type: actions.SET_STATE,
            payload: {
              currenSceneAgenda: agenda.data,
            },
          })
        }
      }
    }
  } catch (err) {
    console.log('in error')
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* SAVE_BRIEFCASE(data) {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state
    const result = yield call(saveBriefcase, { id: data.payload.id, token: accessToken })
    if (result.status === 200) {
      message.success('Collected in the Briefcase.')
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* GET_BRIEFCASE() {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state
    const result = yield call(getBriefcase, { token: accessToken })
    if (result.status === 200) {
      const tempBriefcase = {}
      result.data.forEach(key => {
        tempBriefcase[key.id] = key
      })

      yield put({
        type: actions.SET_STATE,
        payload: {
          briefcase: tempBriefcase,
          briefcaseLoading: false,
          isBriefCaseCalled: true,
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}


export function* GET_DEFAULT_REDIRECTION() {
  try {
    const result = yield call(getDefaultURL)
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          defaultRedirection: result.data.url
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* REMOVE_FROM_BRIEFCASE(data) {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state
    const result = yield call(removeBriefcase, { id: data.payload.id, token: accessToken })
    if (result.status === 200) {
      message.success('Successfully removed from Briefcase')
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* SEND_BRIEFCASE_EMAIL() {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state
    const result = yield call(sendBriefcaseEmail, { token: accessToken })
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          briefcaseEmailLoading: false,
        },
      })
      message.success('Kindly check your mail for your Briefcase content')
    } else {
      message.error('Issue in sending mail. Kindly try again later or contact helpdesk.')
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* DOWNLOAD_FILE(data) {
  try {
    yield call(downloadDocument, data.payload)
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* ASSIGN_CHAIR(data) {
  try {
    // yield call(downloadDocument, data.payload)
    const state = yield select()
    const {
      virtualEvent: { networkingZoneTopology, scene },
      virtualUser: {
        id,
        name,
        designation,
        organization,
        avatarType,
        avatarData,
        avatarColor,
        accessToken,
      },
      Chat: { pubnub },
    } = state

    yield call(assignChair, {
      content: { tableId: data.payload.tableID, chairId: data.payload.chairID },
      token: accessToken,
    })
    const tempTopo = networkingZoneTopology
    const dataTobeAssigned = {
      id,
      name,
      designation,
      organization,
      avatar:
        avatarType === 'text'
          ? `https://via.placeholder.com/100x100.png/${avatarColor.substring(
              1,
            )}/fff?text=${avatarData}`
          : avatarData,
    }
    if (Object.prototype.hasOwnProperty.call(tempTopo, data.payload.tableID)) {
      tempTopo[data.payload.tableID][data.payload.chairID] = dataTobeAssigned
    } else {
      tempTopo[data.payload.tableID] = {}
      tempTopo[data.payload.tableID][data.payload.chairID] = dataTobeAssigned
    }

    yield call(pubnubPublish, {
      pubnub,
      channelID: scene.id,
      message: {
        type: 'chairAssignment',
        userData: dataTobeAssigned,
        tableID: data.payload.tableID,
        chairID: data.payload.chairID,
      },
    })

    yield put({
      type: actions.SET_STATE,
      payload: {
        networkingZoneTopology: tempTopo,
        assignedTable: data.payload.tableID,
        assignedChair: data.payload.chairID,
      },
    })
  } catch (err) {
    console.log(err)
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* LEAVE_CHAIR() {
  try {
    // yield call(downloadDocument, data.payload)
    const state = yield select()
    const {
      virtualEvent: { assignedTable, assignedChair, networkingZoneTopology, scene },
      virtualUser: { accessToken },
      Chat: { pubnub },
    } = state

    yield call(leaveChair, { token: accessToken })
    const tempTopo = networkingZoneTopology
    if (Object.prototype.hasOwnProperty.call(tempTopo, assignedTable)) {
      delete tempTopo[assignedTable][assignedChair]
    }

    yield call(pubnubPublish, {
      pubnub,
      channelID: scene.id,
      message: {
        type: 'leaveChair',
        tableID: assignedTable,
        chairID: assignedChair,
      },
    })

    yield put({
      type: actions.SET_STATE,
      payload: {
        networkingZoneTopology: tempTopo,
        assignedTable: '',
        assignedChair: '',
      },
    })
  } catch (err) {
    console.log(err)
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* GET_NETWORK_TOPOLOGY() {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state
    const result = yield call(getTopology, { token: accessToken })
    console.log('iniside get topology')

    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          networkingZoneTopology: result.data.topology,
          assignedTable: result.data.assignedTable,
          assignedChair: result.data.assignedChair,
        },
      })
    }
  } catch (err) {
    console.log(err)
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* SEND_LEAD(data) {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken, id, name, email, number, organization, designation },
      Chat: { pubnub },
      virtualEvent: { hideNumber, scene },
    } = state

    const result = yield call(pubnubPublish, {
      pubnub,
      channelID: scene.id,
      message: {
        type: 'incominglead',
        name,
        email,
        number: hideNumber ? '************' : number,
        organization,
        designation,
        customMessage: data.payload.customMessage,
        avatar: data.payload.theDP,
        id,
      },
    })

    yield put({
      type: actions.SET_STATE,
      payload: {
        isDropCardVisible: false,
      },
    })

    const finalJson = {
      channelID: scene.id,
      message: {
        type: 'incominglead',
        name,
        email,
        number: hideNumber ? '************' : number,
        organization,
        designation,
        avatar: data.payload.theDP,
        customMessage: data.payload.customMessage,
      },
      timetoken: result.timetoken,
    }

    const dbResult = yield call(saveLead, { content: finalJson, token: accessToken })
    if (dbResult.status === 200) {
      message.success(`Visiting Card Successfully Shared.`)
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* GET_LEADERBOARD() {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state
    yield put({
      type: actions.SET_STATE,
      payload: {
        leaderBoardLoading: true,
      },
    })
    const result = yield call(getLeaderBoard, { token: accessToken })
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          leaderBoardData: result.data,
          leaderBoardLoading: false,
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* FETCH_JUKEBOX() {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state
    const result = yield call(fetchJukebox, { token: accessToken })
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          jukebox: result.data,
          currentJukeboxPlaylist: result.data[0],
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FILTER_SCENE, FILTER_SCENE),
    takeEvery(actions.GET_DEFAULT_SCENE, GET_DEFAULT_SCENE),
    takeEvery(actions.SAVE_BRIEFCASE, SAVE_BRIEFCASE),
    takeEvery(actions.GET_BRIEFCASE, GET_BRIEFCASE),
    takeEvery(actions.REMOVE_FROM_BRIEFCASE, REMOVE_FROM_BRIEFCASE),
    takeEvery(actions.SEND_BRIEFCASE_EMAIL, SEND_BRIEFCASE_EMAIL),
    takeEvery(actions.DOWNLOAD_FILE, DOWNLOAD_FILE),
    takeEvery(actions.SEND_LEAD, SEND_LEAD),
    takeEvery(actions.ASSIGN_CHAIR, ASSIGN_CHAIR),
    takeEvery(actions.LEAVE_CHAIR, LEAVE_CHAIR),
    takeEvery(actions.GET_NETWORK_TOPOLOGY, GET_NETWORK_TOPOLOGY),
    takeEvery(actions.GET_LEADERBOARD, GET_LEADERBOARD),
    takeEvery(actions.FETCH_JUKEBOX, FETCH_JUKEBOX),
    takeEvery(actions.GET_DEFAULT_REDIRECTION, GET_DEFAULT_REDIRECTION),

    // takeEvery(actions.FILTER_NAVIGATION_SCENE, FILTER_NAVIGATION_SCENE),
    // GET_DEFAULT_SCENE(), // run once on app load to fetch menu data
  ])
}
