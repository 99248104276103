import React from 'react'
import { Row, Col, Avatar } from 'antd'
import PointingContainer from '../PointingContainer'
import ScoreBoard from '../ScoreBoard'

import './userscore.less'

/* eslint-disable react/jsx-indent, camelcase,no-nested-ternary */

class UserScore extends React.Component {
  render() {
    const {
      restRankerDetails: { points, name, my_rank, scoreboard },
    } = this.props
    return (
      <div className="user_score_root_container">
        <Row>
          <Col xs={24} sm={24} md={3}>
            <span className="rankcard_desc_container">
              <Avatar size={48}>{name !== undefined ? name.charAt(0).toUpperCase() : ''}</Avatar>
            </span>
          </Col>
          <Col xs={24} sm={24} md={16}>
            <span className="rankcard_desc_container" id="user_dec_card">
              <h3>{name}</h3>
              <p className="user_rank">{`My Rank : ${my_rank !== undefined ? my_rank : 'NA'}`}</p>
            </span>
          </Col>
          <Col xs={24} sm={24} md={5} id="rankcard_last_col">
            <PointingContainer points={points} />
          </Col>
          <Col span={24}>
            <ScoreBoard scoreboard={scoreboard} />
          </Col>
        </Row>
      </div>
    )
  }
}

export default UserScore
