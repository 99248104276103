import actions from './actions'

const initialState = {
  isContestOpen: false,
  currentContestID: '',
  currentContestType: '',
  currentMaxSize: '',
  contestEnteries: [],
  theImageSet: [],
  currentPage: 0,
  pageSize: 25,
  currentView: 'list',
  currentIndex: null,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
