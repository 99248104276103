import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button } from 'antd'
import Iframe from 'react-iframe'
import actions from 'redux/virtual/event/actions'
import './gamingScreen.less'

/* eslint-disable react/jsx-indent */

const mapStateToProps = ({ virtualEvent, virtualUser }) => ({ virtualEvent, virtualUser })
@connect(mapStateToProps)
class GamingScreen extends React.Component {
  handleStartGame = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isGameStart: true,
      },
    })
  }

  render() {
    const {
      virtualEvent: { gamingData, isGameStart },
      virtualUser: { id },
    } = this.props
    console.log("getting gaming data")
    console.log(gamingData)
    console.log('process.env.REACT_APP_ENDPOINT', process.env.REACT_APP_ENDPOINT)
    const endpoint = process.env.REACT_APP_ENDPOINT
    return (
      <div className="gaming_screen text-center">
        <ul className="circles">
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
        </ul>
        {isGameStart ? (
          <Iframe
            url={`${gamingData.gameurl}?gameid=${gamingData.widgetID}&userid=${id}&endpoint=${endpoint}&pointingsystem=${gamingData.pointsystem}`}
            width="100%"
            height="calc(100vh - 200px)"
            loading="eager"
            className="theiFrame"
            display="initial"
            position="relative"
            frameborder="0"
          />
        ) : (
          <Row>
            <Col xs={24} sm={24} md={10}>
              <span className="game_logo_container">
                <img src={gamingData.gameIcon} className="game_logo_img" alt="game logo" />
              </span>
            </Col>
            <Col md={1} />
            <Col xs={24} sm={24} md={12}>
              <span className="game_desc_container">
                <h2 style={{ fontWeight: 900 }}>{gamingData.gamename}</h2>
                <br />
                <p>{gamingData.game_instn}</p>

                <div className="points-row">
                  <span className="points-container">
                    <p>{gamingData.pointsystem} points</p>
                    <p>&nbsp;= 1 </p>
                    <img src="resources/images/badge.png" alt="coin" />
                    
                  </span>
                </div>
                <br />
                <Button type="primary" onClick={this.handleStartGame}>
                  Start Game
                </Button>
              </span>
            </Col>
            <Col md={1} />
          </Row>
        )}
      </div>
    )
  }
}

export default GamingScreen
