import React from 'react'
import { connect } from 'react-redux'
import { Button, Space } from 'antd'
import classNames from 'classnames'
import { MessageFilled, PullRequestOutlined } from '@ant-design/icons'
import actions from 'redux/virtual/chat/actions'
import { FormattedMessage } from 'react-intl'
import AttendeeMessageWindow from '../../InternalComponent/Attendees'
// import Messages from '../../InternalComponent/Messages'
import styles from '../../style.module.scss'

const mapStateToProps = ({ Chat, virtualUser }) => ({ Chat, virtualUser })

@connect(mapStateToProps)
class HomeScreen extends React.Component {
  closeSettings = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'virtualSettings/CHANGE_SETTING',
      payload: {
        setting: 'isSidebarOpen',
        value: false,
      },
    })
  }

  changeTab = event => {
    const { dispatch } = this.props
    const theTab = event.target.getAttribute('data-value')
    if (theTab === 'attendees') {
      dispatch({
        type: actions.GET_ALL_USERS,
      })
    }
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentTab: theTab,
      },
    })
  }

  render() {
    const {
      Chat: { currentTab },
      virtualUser: { roles },
    } = this.props
    return (
      <div className={styles.container}>
        <button
          className={`${styles.close} fa fa-times`}
          onClick={this.closeSettings}
          type="button"
        />
        <h2 className={styles.mediah2}>
          <FormattedMessage id="chat.heading" />
        </h2>
        <h4 className={styles.mediah4}>
          <FormattedMessage id="chat.subheading" />
        </h4>
        <br />
        <Space>
          <Button
            type="primary"
            shape="round"
            className={classNames({
              [styles.radioButtonChat]: true,
              [styles.radioButtonChatActive]: currentTab === 'messages',
            })}
            data-value="messages"
            icon={<MessageFilled />}
            onClick={this.changeTab}
          >
            &nbsp;
            <FormattedMessage id="chat.messages" />
          </Button>

          {roles.includes('ROLE_EXHIBITOR') ? (
            <Button
              type="primary"
              shape="round"
              className={classNames({
                [styles.radioButtonChat]: true,
                [styles.radioButtonChatActive]: currentTab === 'requests',
              })}
              data-value="requests"
              icon={<PullRequestOutlined />}
              onClick={this.changeTab}
            >
              &nbsp;
              <FormattedMessage id="chat.requests" />
            </Button>
          ) : (
            ""
            // <Button
            //   type="primary"
            //   shape="round"
            //   className={classNames({
            //     [styles.radioButtonChat]: true,
            //     [styles.radioButtonChatActive]: currentTab === 'attendees',
            //   })}
            //   data-value="attendees"
            //   icon={<UsergroupAddOutlined />}
            //   onClick={this.changeTab}
            // >
            //   &nbsp;
            //   <FormattedMessage id="chat.attendees" />
            // </Button>
          )}

          {/* <Button type="primary" shape="round" className={styles.radioButtonChat} icon={<UsergroupAddOutlined />}>
            Attendees
          </Button> */}
        </Space>
        <br />
        <AttendeeMessageWindow />
        {/* {
          currentTab === "attendees"
          ?
            <Attendees />
          :
            <Messages />
        } */}
      </div>
    )
  }
}

export default HomeScreen
