import React from 'react'
import { Modal, Row, Col } from 'antd'
import { connect } from 'react-redux'
import { CloseCircleTwoTone } from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'
import actions from 'redux/virtual/event/actions'
import GamingScreen from './GamingScreen'

import './gaming.less'

const mapStateToProps = ({ virtualEvent }) => ({ virtualEvent })

@connect(mapStateToProps)
class Gaming extends React.Component {
  // constructor(props) {
  //   super(props)
  //   props.dispatch({
  //     type: actions.GET_AGENDA,
  //     payload: {},
  //   })
  // }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isGamingModelOpen: false,
        isGameStart: false,
      },
    })
  }

  render() {
    const {
      virtualEvent: { isGamingModelOpen },
    } = this.props
    return (
      <div>
        <Modal
          visible={isGamingModelOpen}
          style={{
            top: 0,
            display: 'flex',
          }}
          wrapClassName="socialwallModal"
          maskClosable={false}
          // maskStyle={{ marginTop: '48px' }}
          mask
          width="72vw"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={<CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: '25px' }} />}
          destroyOnClose
        >
          {/* <Row gutter={24} id="modalHeader" className="modalHeader">
            <Col span={24}>
              <img
                src="./resources/images/agendaHeader.jpg"
                style={{ width: '100%' }}
                alt="modalHeader"
              />
            </Col>
          </Row> */}
          <Row id="modalContent" className="modalContent">
            <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 50px)">
              <Col span={24}>
                <GamingScreen />
              </Col>
            </Scrollbars>
          </Row>
          {/* <Row gutter={24} id="modalFooter" className="modalFooter">
            <Col span={24}>
              <img
                src="./resources/images/agendaFooter.jpg"
                style={{ width: '100%' }}
                alt="modalFooter"
              />
            </Col>
          </Row> */}
        </Modal>
      </div>
    )
  }
}

export default Gaming
