import React from 'react'
import { Row, Col } from 'antd'
import { connect } from 'react-redux'
import actions from 'redux/virtual/user/actions'
import Preview from '../Preview'

/* eslint-disable prefer-promise-reject-errors, consistent-return,prefer-destructuring */

@connect(({ virtualUser }) => ({ virtualUser }))
class EditProfileForm extends React.Component {
  constructor(props){
    super(props)
    props.dispatch({
      type: actions.SET_STATE,
      payload: {
        options: this.getOptions(props.virtualUser.name)
      }
    })
    
  }

  getOptions = name => {
    const nameFragments = name.split(' ')
    const firstName = nameFragments[0]
    const lastName = nameFragments[nameFragments.length - 1]
    const firstInitial = name.charAt(0)
    let completeInitials = ''
    nameFragments.forEach(key => {
      completeInitials += `${key.charAt(0)} `
    })
    const finalOptions = []
    finalOptions.push(firstName)
    finalOptions.push(lastName)
    finalOptions.push(firstInitial)
    finalOptions.push(completeInitials)
    return finalOptions
  }

  skipSave = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isEditProfileOpen: false,
        // isTutorialVideoVisible: true
      },
    })
  }

  onSubmit = () => {
    // event.preventDefault()
    const {
      dispatch,
    } = this.props
    dispatch({
      type: actions.COMPLETE_EDIT_PROFILE
    })
  }

  inputChange = event => {
    const { dispatch, virtualUser:{options,avatarType} } = this.props
    const name = event.target.getAttribute('data-name')
    let newOptions = options
    if(name === "name")
    {
      newOptions = this.getOptions(event.target.value)
    }
    const theValue = event.target.value
    const payloadtoSend = {
      [name]: theValue,
      options: newOptions,
    }
    if(avatarType === "text")
    {
      payloadtoSend.avatarData = newOptions[0]
    }
    dispatch({
      type: actions.SET_STATE,
      payload : payloadtoSend
    })
  }

  updateElementScore = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'virtualUser/UPDATE_ELEMENT_SCORE',
      payload: {
        touchPointId: 4,
        elementId: 'editPhotofixedElementID',
        touchPointName: 'Change Profile Picture'
      },
    })
  }


  render() {
    // const {
    //   // virtualUser: { name, editProfileLoading },
    // } = this.props
    return (
      <div>
        <Row gutter={16}>
          <Col xs={24} md={24} lg={24} xl={24} className="text-center">
            <Preview />
          </Col>
          {/* <Col xs={24} md={16} lg={14} xl={14}>
            <Form
              name="register"
              onFinish={this.onSubmit}
              scrollToFirstError
              layout="vertical"
              hideRequiredMark
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please enter your name' }]}
                initialValue={name}
              >
                <Input 
                  placeholder="What do they call you?" 
                  defaultValue={name}
                  data-name="name"
                  onChange={this.inputChange}
                  value={name}
                />
              </Form.Item>

              <Form.Item
                name="confirmagree"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value ? Promise.resolve() : Promise.reject('Testimony required.'),
                  },
                ]}
              >
                <Checkbox>I understand that the information provided above will be visible to other attendees during the event.</Checkbox>
              </Form.Item>

              <div className="form-actions">
                
                <Form.Item>
                  <Space>
                    <Button type="text" htmlType="button" onClick={this.skipSave}>
                      Skip
                    </Button>
                    <Button type="primary" className="width-150 mr-4" htmlType="submit" loading={editProfileLoading}>
                      Confirm
                    </Button>
                  </Space>
                </Form.Item>
                
              </div>
            </Form>
          </Col> */}
        </Row>
      </div>
    )
  }
}

export default EditProfileForm
