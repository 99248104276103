import React from 'react'
import { Button, Avatar,Card, Row, Col, Tooltip,Upload } from 'antd'
// import { EditOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import actions from 'redux/virtual/user/actions'
// import styles from '../style.module.scss'
import './preview.less'

/* eslint-disable prefer-promise-reject-errors, prefer-template */

@connect(({ virtualUser }) => ({ virtualUser }))
class AvatarForm extends React.Component {
  state = {
    currentIndexOption: 0,
  }

  getRandomInt = (max)=> {
    return Math.floor(Math.random() * Math.floor(max));
  }

  changeUser = () => {
    const { currentIndexOption } = this.state
    const {
      dispatch,
      virtualUser: { options, colors },
    } = this.props
    const currentIndexOptionNow =
      currentIndexOption < options.length - 1 ? currentIndexOption + 1 : 0
    const currentIndexColorNow = this.getRandomInt(colors.length)
    this.setState({currentIndexOption: currentIndexOptionNow})
    dispatch({
      type: actions.SET_STATE,
      payload : {
        avatarData: options[currentIndexOptionNow],
        avatarColor: colors[currentIndexColorNow],
        avatarType:"text"
      }
    })
  }

  avatarUpload = ({ file, onSuccess, onProgress, onError }) => {
    const { dispatch } = this.props
    dispatch({
      type: actions.EDIT_AVATAR,
      payload: {
        file,
        onSuccess,
        onError,
        onProgress,
      },
    })
  }

  render() {
    // const { currentIndexOption, currentIndexColor } = this.state
    const {
        virtualUser: { name, avatarType,avatarData,avatarColor },
    } = this.props
    return (
      <div>
        <Card className="previewDesign">
          <Row justify="space-around" align="middle">
            {/* <Col span={6}>
              <Tooltip title="Change Avatar">
                <Button shape="circle" icon={<SwapOutlined />} onClick={this.changeUser} />
              </Tooltip>
            </Col> */}
            <Col span={14}>
              {avatarType === 'text' ? (
                <Avatar
                  style={{
                    backgroundColor: avatarColor,
                    verticalAlign: 'middle',
                  }}
                  size={100}
                >
                  {avatarData}
                </Avatar>
              ) : (
                <Avatar src={avatarData} size={100} />
              )}
            </Col>
            <Col span={10}>
              <Upload customRequest={this.avatarUpload}>
                <Tooltip title="Upload Picture">
                  <Button type="primary" onClick={this.updateElementScore}>
                    Upload Photograph
                  </Button>
                </Tooltip>
              </Upload>
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={14}>
              <h3>{name}</h3>
            </Col>
            <Col span={10} />
          </Row>
        </Card>

      </div>
    )
  }
}

export default AvatarForm
