import React from 'react'
import { Modal } from 'antd'
import { connect } from 'react-redux'
import actions from 'redux/virtual/eshop/actions'
// import 'antd/dist/antd.css'
import ItemDescription from './ItemDescription'
import './itemcard.less'

/* eslint-disable react/jsx-indent,camelcase,jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions,jsx-a11y/mouse-events-have-key-events */
const { confirm } = Modal
const mapStateToProps = ({ eshop }) => ({
  eshop,
})

@connect(mapStateToProps)
class ItemCard extends React.Component {
  info = (description, name, id, image) => {
    const { dispatch } = this.props
    confirm({
      // title: name,
      icon: '',
      content: <ItemDescription description={description} name={name} id={id} image={image} />,
      okText: 'Redeem',
      className: 'eshopRedeemModel',
      okButtonProps: { className: 'okButton' },
      cancelButtonProps: { className: 'CancelBtn' },
      confirmLoading: true,
      width: '50vw',
      onOk() {
        dispatch({
          type: actions.REDEEM_GIFTS,
          payload: {
            giftid: id,
          },
        })
      },
      onCancel() {},
    })
  }

  render() {
    const {
      gift: { name, token_left, id, description, image },
    } = this.props

    return (
      <div
        className="eshop-card"
        onClick={() => this.info(description, name, id, image)}
        style={{
          filter: token_left <= 0 ? 'grayscale(1)' : 'grayscale(0)',
          pointerEvents: token_left <= 0 ? 'none' : '',
        }}
      >
        <img src={image} alt={name} />
        {token_left <= 0 ? <div className="soldout">Sold out</div> : ''}
      </div>
    )
  }
}

export default ItemCard
