import React from 'react'
import { Button, Tooltip } from 'antd'
import {
  // FullscreenExitOutlined,
  FullscreenOutlined,
} from '@ant-design/icons'
import { connect } from 'react-redux'
import './fullscreenButton.less'

/* eslint-disable no-lonely-if */
@connect(({ virtualSettings }) => ({ virtualSettings }))
class FullScreenButton extends React.Component {
  toggleFullScreen = elem => {
    try {
      // ## The below if statement seems to work better ## if ((document.fullScreenElement && document.fullScreenElement !== null) || (document.msfullscreenElement && document.msfullscreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (
        (document.fullScreenElement !== undefined && document.fullScreenElement === null) ||
        (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) ||
        (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
        (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)
      ) {
        if (elem.requestFullScreen) {
          elem.requestFullScreen()
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen()
        } else if (elem.webkitRequestFullScreen) {
          elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen()
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  // checkFullScreen = () => {
  //   try
  //   {
  //     // ## The below if statement seems to work better ## if ((document.fullScreenElement && document.fullScreenElement !== null) || (document.msfullscreenElement && document.msfullscreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
  //     if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
  //       return true
  //     }
  //     return false
  //   }
  //   catch(err){
  //     console.log(err)
  //     return false
  //   }
  // }

  render() {
    // const isFullScreen = this.checkFullScreen()
    // const theIcon = isFullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />
    const {
      virtualSettings: { isDefaultFullScreen },
    } = this.props
    return (
      <>
        {isDefaultFullScreen ? (
          <Tooltip title="Switch to Fullscreen for Better Experience" placement="topLeft">
            <Button
              type="primary"
              className="fullscreenwidget"
              shape="circle"
              icon={<FullscreenOutlined />}
              onClick={() => {
                this.toggleFullScreen(document.body)
              }}
              size="large"
            />
          </Tooltip>
        ) : (
          ''
        )}
      </>
    )
  }
}

export default FullScreenButton
