import React from 'react'
import { Empty, Button } from 'antd'
import ImageGallery from 'react-image-gallery'
import { connect } from 'react-redux'
import actions from 'redux/virtual/photobooth/actions'

const mapStateToProps = ({ photobooth }) => ({ photobooth })

@connect(mapStateToProps)
class Gallery extends React.Component {
  constructor(props) {
    super(props)
    props.dispatch({
      type: actions.FETCH_PHOTOBOOTH_ENTERIES
    })
  }

  goBack = () => {
    const {
      dispatch,
      photobooth: { currentStep },
    } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: currentStep - 1,
        toonifiedImage: '',
        imageProcessing: false,
      },
    })
  }

  resetPhotobooth = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: 0,
        imageSrc: '',
        imageProcessing: false,
        webcamLoading: true,
        currentScreen: 'photobooth'
      },
    })
  }

  render() {
    const {
      photobooth: { galleryData },
    } = this.props
    console.log("checking gallery data")
    console.log(galleryData)

    return (
      <>
        {
        galleryData.length === 0 
        ?
          <>
            <Empty
              description={
                <span>
                  Be the First one to Click a photograph
                </span>
              }
            >
              <Button type="primary" onClick={this.resetPhotobooth}>Take a photo now</Button>
            </Empty>
          </>
        :
          <>
            <ImageGallery
              items={galleryData}
              infinite={false}
              showPlayButton={false}
              // renderCustomControls={this.customLikeButton}
              // onSlide={this.slideChange}
              // onBeforeSlide={this.slideChange}
              additionalClass="gallery"
              lazyLoad
              ref={i => {
                this.contestRef = i
              }}
            />
            <br />
            <Button type="primary" onClick={this.resetPhotobooth}>Go Back</Button>
          </>
        }
      </>
    )
  }
}

export default Gallery