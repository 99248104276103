import React from 'react'
import { Row, Col, Empty } from 'antd'
import { connect } from 'react-redux'
import actions from 'redux/virtual/contest/actions'
import ImageGallery from 'react-image-gallery'

import '../contest.less'
import '../heart.less'

const mapStateToProps = ({ contest }) => ({ contest })
/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
@connect(mapStateToProps)
class EntryViewer extends React.Component {
  constructor(props) {
    super(props)
    const {
      contest: { currentPage },
    } = props
    props.dispatch({
      type: actions.FETCH_CONTEST_ENTERIES,
      payload: {
        currentPage,
      },
    })
  }

  state = {
    currentIndex: 0,
  }

  componentDidUpdate() {
    const {
      contest: { currentIndex },
    } = this.props

    if (currentIndex !== null && this.contestRef !== null) {
      this.contestRef.slideToIndex(currentIndex)
    }
  }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isContestOpen: false,
      },
    })
  }

  initiateLikeDislike = () => {
    const {
      dispatch,
      contest: { contestEnteries, theImageSet },
    } = this.props
    const { currentIndex } = this.state
    const thenewEntry = contestEnteries
    const theEntry = thenewEntry[theImageSet[currentIndex].originalIndex]
    const totalLikes = parseInt(theEntry.total_likes, 10)
    if (theEntry.liked_by_user) {
      theEntry.total_likes = totalLikes - 1
      theEntry.liked_by_user = false
      dispatch({
        type: actions.REMOVE_LIKE,
        payload: {
          entryID: theImageSet[currentIndex].entry_id,
        },
      })
    } else {
      theEntry.total_likes = totalLikes + 1
      theEntry.liked_by_user = true
      dispatch({
        type: actions.ADD_LIKE,
        payload: {
          entryID: theImageSet[currentIndex].entry_id,
        },
      })
    }

    dispatch({
      type: actions.SET_STATE,
      payload: {
        contestEnteries: thenewEntry,
      },
    })
  }

  customLikeButton = () => {
    const { currentIndex } = this.state
    const {
      contest: { contestEnteries, theImageSet },
    } = this.props

    const theEntry = contestEnteries[theImageSet[currentIndex].originalIndex]
    console.log(theImageSet[currentIndex].originalIndex)
    const theClass = theEntry.liked_by_user ? 'heart is-active' : 'heart'
    return (
      <>
        <div className="theName">
          <p>{theEntry.uploaded_by_name}</p>
        </div>
        <div className="stage">
          <div className={theClass} onClick={this.initiateLikeDislike} />
          <br />
          <p className="likeCount">{`${theEntry.total_likes} Likes`}</p>
        </div>
      </>
    )
  }

  slideChange = index => {
    const {
      dispatch,
      contest: { theImageSet, currentPage, pageSize },
    } = this.props
    this.setState({ currentIndex: index })
    console.log('Checking the blockage')
    console.log(index)
    console.log(theImageSet.length)
    console.log(currentPage)
    console.log((currentPage + 1) * pageSize)
    console.log('***********')
    if (index >= theImageSet.length - 3 && (currentPage + 1) * pageSize >= theImageSet.length) {
      dispatch({
        type: actions.FETCH_CONTEST_ENTERIES,
        payload: {
          currentPage: currentPage + 1,
        },
      })
      dispatch({
        type: actions.SET_STATE,
        payload: {
          currentPage: currentPage + 1,
        },
      })
    }
  }

  render() {
    const {
      contest: { theImageSet },
    } = this.props

    return (
      <>
        {theImageSet.length !== 0 ? (
          <Row justify="space-around" align="middle" className="galleryZone">
            <Col span={24} className="text-center">
              <ImageGallery
                items={theImageSet}
                infinite={false}
                showPlayButton={false}
                renderCustomControls={this.customLikeButton}
                // onSlide={this.slideChange}
                onBeforeSlide={this.slideChange}
                additionalClass="gallery"
                lazyLoad
                ref={i => {
                  this.contestRef = i
                }}
              />
            </Col>
          </Row>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <>
                <h2>No Enteries Yet</h2>
                <span style={{ color: '#d3d3d3' }}>
                  Be the first one to submit the entry and increase your chance to win.
                </span>
              </>
            }
          />
        )}
      </>
    )
  }
}

export default EntryViewer
